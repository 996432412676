import React, {useEffect, useState} from 'react'

import logo from './thefarm.svg';
import birdie from './birdie.jpg';
import birdieMob from './birdie_mobil.jpg';
import './App.scss';

function App() {
  const [winWidth, setWinWidth] = useState()

  useEffect(() => {
    _checkForDevicetype()
    window.addEventListener('resize', () => _checkForDevicetype());
  }, [])

  const _checkForDevicetype = () => {
    setWinWidth(window.innerWidth)
  }

  return (
    <div className="App">
      <img src={logo} className="logo"/>
      <img src={winWidth > 600 ? birdie : birdieMob} className="birdie"/>
      <div className="title-wrapper">
        <h1 className="title">line up for<br/>grand opening<br/>of the farm.dk</h1>
        <h3 className="subtitle">in this life or the next</h3>
      </div>
    </div>
  );
}

export default App;
